import { useStaticQuery, graphql } from "gatsby"

export const useLinkTable = (url) => {
  const { allStoryblokLink } = useStaticQuery(graphql`
    query LinkTableQuery {
      allStoryblokLink {
        nodes {
          uuid
          name
          slug
          alternates {
            lang
            name
            path
          }
        }
      }
    }
  `)

  return allStoryblokLink.nodes.find((link) => {
    if (link.alternates !== null) {
      return (
        link.slug === url ||
        link.alternates.find((alternate) => alternate.path === url)
      )
    } else {
      return link.slug === url
    }
  })
}
